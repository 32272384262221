.App {
  text-align: center;
  background-image: url("../public/wallpaper.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
}
body {
  overflow: hidden; /* Hide scrollbars */
}
.bottom-bar {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 50px;
  text-align: left;
  background-color: #232323;
  display: flex;
  flex-direction: row;
  z-index: 5;
}
.bottom-logo {
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
}
.bottom-icon {
  width: 40px;
  height: 40px;
  padding: 2px 5px;
  margin-left: 5px;
  cursor: pointer;
  border-bottom: #00579efb 4px solid;
}

.desktopicon {
  margin-left: 20px;
  margin-top: 20px;
  width: 50px;
  font-size: 14px;
  cursor: pointer;
  border: 1px dotted #ffffff00;
}
.desktopicon img {
  width: 50px;
}
.desktopicon p {
  margin: 0;
  padding: 0;
  color: white;
}
.active {
  background-color: #00579e7a;
  border: 1px dotted #ffffff;
}

.windowframe {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0 0 10px #000000;
  overflow: hidden;
}
.windowframe p {
  margin: auto;
  margin-left: 5px;
  margin-right: 0px;
  padding: 0;
}
.windowframe iframe {
  width: 100%;
  height: calc(100% - 30px);
  border: none;
  user-select: all;
}
.windowtop {
  width: 100%;
  height: 30px;
  background-color: #ffffff;
  text-align: left;
  display: flex;
  flex-direction: row;
}
.windowicon {
  width: 20px;
  height: 20px;
  margin: 5px;

}
.barbutton {
  width: 50px;
  height: 30px;
  cursor: pointer;
}
.barbutton img {
  width: 15px;
  height: 15px;
  margin: 7px 17px;
}
.barbutton:hover {
  background-color: #cfcfcf;
}
.resize {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 15px;
  height: 15px;
  cursor: nwse-resize;
}
.bottom-date {
  margin-left: auto;
  width: 100px;
  height: 30px;
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}