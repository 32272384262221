@font-face {
    font-family: 'Linux Libertine';
    src: local('Linux Libertine'), url(./fonts/LinLibertine.ttf) format('ttf');
  }
  @font-face {
    font-family: 'Linux Libertine DR';
    src: local('Linux Libertine DR'), url(./fonts/LinLibertine_DR.ttf) format('ttf');
  }
.infomain {
    width: 100%;
    height: calc(100% - 50px);
    font-family: 'Linux Libertine';
    text-align: left;
    user-select: text;
    overflow: auto;
}
.biosec {
    width: calc(100% - 50px);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: 'Linux Libertine DR';
    margin: 15px 25px;
}
.biosec img {
    width: 200px;
}
.biosec p {
    text-indent: 30px;
    max-width: 500px;
    margin-top: 0px;
}